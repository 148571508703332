@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

@import "_normalize.scss";
@import "_variables.scss";
@import "_mixins.scss";
@import "_input.scss";
@import "_responsivemenu.scss";
@import "flag-icon.scss";
@import "_flag-icon-base.scss";
@import "_flag-icon-list.scss";

//// BASE STYLES



*,
*:after,
*:before {
	box-sizing: border-box;

}
html {
	font-size: 62.5%;
	height: 100%;
} 

body {
	font-size: 1.5em;
	line-height: 1.6;
	font-weight: 400;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}



*:focus {
	outline: none;
}


a {
  text-decoration: none;
  transition: all 0.3s linear 0s;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;

}

li {
	list-style: none;
	
}

h2 {
	font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	list-style-type: none;
}



/* Container\Clearfix */

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}


.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px; }




  ///////////////
  // MAIN NAV
  ///////////////

  .navbar {
  	//padding-top: 5px;
  	//padding-bottom: 5px;
  //	background-color: red;

   
    
  ul {

    .main-lang {
      
      
      >  a {
           position: relative;
           margin: 0;
           margin-left: 15px;
           font-weight: 400;
           letter-spacing: 1px;
           background-color: $accent-color;
           border-radius: 25px;
           color: #fff;
           padding: 7px 0;
           padding-left: 16px;
           padding-right: 16px;

           &:hover {
             color: #fff;
           }
            
         }
    }
      
        li {

          position: relative;
          display: inline-block;


          ul {
            position: absolute;
            top: 126%;
            left: 20%;
            width: 100px;
            z-index: 99999;
            background-color: #eee;
            transform: scale(1, 0);
            transform-origin: center top;
            opacity: 0;
            // transition: visibility linear 0.1s, opacity 0.1s;

            transition: opacity ease 0.25s;

            li {
              display: block;
              

              &:hover {
                
                transition: 0.3s;
                background-color: #ccc;
              }

              a {
                display: block;
                margin: 0;
                font-weight: 300;
                font-size: 14px;
                color: #000;
                padding: 12px;

                &:hover {
                  color: #000;
                }
              }
            }


          }

          &:hover {
            > ul {
              transform: scale(1,1);
              opacity: 1;
            }
          }
         
        }
        
         
        
    
    }

  	.mobile-menu-trigger {
  		display: none;
      margin-right: auto;
      

  	}

  	.container {
  		display: flex;
  		justify-content: space-between;
  		padding-top: 15px;
  		padding-bottom: 15px;

  		> * {
  			align-self: center;
  			margin: 0;
  		}

  		.logo {
  		//	flex-shrink: 0;
  			margin-right: auto;
  		//	font-size: 25px;
  		//	font-weight: 700;
  			//text-transform: uppercase;
  			//letter-spacing: 1px;
  			//color: $main-color;
  			// transition: .3s;
  			//font-family: 'Poppins', Helvetica, Arial, sans-serif;
        width: 15%;

        @include responsive(md) {
          width: 20%;
        }

        @include responsive(sm) {
          width: 25%;
        }

        @include responsive(xs) {
          width: 35%;
        }

        @include responsive(xxs) {
          width: 50%;
        }

  		}

  		.flex-nav {
  			ul {
  				li {
  					display: inline-block;
  					position: relative;
  					

  					a {
						color: $main-color;
						display: block;
						margin: 20px;
						font-size: 13px;
						letter-spacing: 0.4px;
						transition: .3s;
						text-transform: uppercase;
						font-weight: 700;
						font-family: 'Poppins', Helvetica, Arial, sans-serif;

						&:hover {
							color: rgba(0,0,0,0.5);
            }
            
            }
            
            ul {
              position: absolute;
              top: 100%;
              left: 20%;
              width: 100px;
              z-index: 99999;
              background-color: #eee;
              transform: scale(1, 0);
              transform-origin: center top;
              opacity: 0;
              // transition: visibility linear 0.1s, opacity 0.1s;

              transition: opacity ease 0.25s;

              li {
                display: block;
                

                &:hover {
                  
                  transition: 0.3s;
                  background-color: #ccc;
                }

                a {
                  display: block;
                  margin: 0;
                  font-weight: 400;
                  padding: 12px;

                  &:hover {
                    color: #000;
                  }
                }
              }
            }

            &:hover {
              > ul {
                transform: scale(1,1);
                opacity: 1;
              }
            }

  				}
        }
        
        .language {
          
        >  a {
            position: relative;
            margin: 0;
            margin-left: 15px;
            font-weight: 400;
            letter-spacing: 1px;
            background-color: $accent-color;
            border-radius: 25px;
            color: #fff;
            padding: 7px 0;
            padding-left: 16px;
            padding-right: 16px;

            &:hover {
              color: #fff;
            }
             
          }
        }
  		}
  	}

  	@include responsive(md) {
  		padding: 10px 5vw;

  		.mobile-menu-trigger {
  			display: block;

  			span {
  				background-color: $main-color;
  			}

  		}

  		.flex-nav {
  			display: none;
  		}

  		.container .logo {
  			margin-right: 0;
  		}

  	}
  }


  ///////////////
  // MAIN HEADER
  ///////////////


  .main-header {

    position: relative;
    text-align: center;
    width: 100%;
      
      .title {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-font-smoothing: antialiased;



          h1 {
            color: #fff;
            font-size: 60px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            margin: 0;
            text-shadow: 0px 0px 2px #000;

            @media (max-width: 540px) {
              font-size: 40px;
            }

          
          }

          .line {

            height: 3px;
            width: 55px;
            background-color: $accent-color;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 5px;
          }

          p {
            margin: 0 auto;
            font-size: 17px;
            color: #fff;
            font-weight: 300;
            letter-spacing: 0.5px;
            max-width: 75%;
            width: 100%;
            margin-bottom: 60px;
            text-shadow: 0px 0px 3px #000;
            -webkit-font-smoothing: antialiased;
          

            @media (max-width: 1285px) {
              max-width: 85%;
            }

            @media (max-width: 1120px) {
              max-width: 95%;
            }

            @media (max-width: 540px) {
              font-size: 15px;
            }

        


          }

          a {
            text-transform: uppercase;
            font-size: 15px;
            letter-spacing: 1.5px;
            color:#fff;
            background-color: $accent-color;
           
            padding: 15px 28px;
            border-radius: 50px;
            transition: ease-in 0.3s;

           
            &:hover {
             background-color: darken($accent-color, 5%);
             padding: 15px 40px;
            }

            @include responsive(lg) {
              font-size: 14.5px;
              padding: 14px 26px;
              &:hover {
                padding: 14px 38px;
              }
            }

            @media (max-width: 492px) {
              font-size: 13px;
              padding: 13px 24px;
              &:hover {
                padding: 13px 34px;
              }
            }
            
          }

          @include responsive(md) {
            width: 60%; 
          }

          @media (max-width: 855px) {
            width: 70%;
          }

          @include responsive(xxs) {
            width: 80%; 
          }
        }

  .slider {
   

   

    .slide-1 {
      background-image: url('../images/pera-hero-2.jpg');
      background-position: 100% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 720px;

      @media (max-width: 1300px) {
        background-position: 80% 100%;
      }
 
    }

    .slide-2 {
      background-image: url('../images/slide-2.jpg');
      background-position: 100% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      height: 720px;


      @media (max-width: 1050px) {
        background-position: 100% 50%;
      }

      @media (max-width: 830px) {
        background-position: 75% 50%;
      }
      

      @media (max-width: 638px) {
        background-position: 60% 100%;
      }

      @media (max-width: 537px) {
        background-position: 55% 100%;
      }

      @media (max-width: 460px) {
        background-position: 47% 100%;
      }

      @include responsive(xxs) {
        background-position: 45% 100%;
      }
      
    }

    .slide-3 {
      background-image: url('../images/slide-3.jpg');
      background-position: 100% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      height: 720px;

      @media (max-width: 490px) {
        background-position: 90% 100%;
      }

      @include responsive(xxs) {
        background-position: 80% 100%;
      }
    }



  }
  	
  }




  ///////////////
  // THREE COL SECTION 
  ///////////////


  .work {

  	padding-top: 50px;
    padding-bottom: 100px;
    
    @media (max-width: 500px) {
      padding-bottom: 70px;
    }
  	

  	.grid-container {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 15px;

  		.grid-col {

        

  			.title {

				vertical-align: top;

  				h2 {
  					margin: 0;
  					letter-spacing: 0.2px;
  					color: $title-color;
  				}

  				.line {
					width: 55px;
					height: 3px;
					margin-top: 12px;
					margin-bottom: 12px;
					background-color: $accent-color;

  				}

  				p {

  					max-width: 85%;
  					width: 100%;
  					margin: 0;
  					margin-bottom: 5px;
  					line-height: 26px;
            color: $p-color;
           
            
           
            
            @media (max-width: 700px) {
              max-width: 95%;
            }
  				
  				}

  				a {
  					text-decoration: underline;
            color: $accent-color;
            transition: 0.3s;

            &:hover {
              color: rgba($accent-color, 0.75);
            }
  				}
  			}
  		}
  	}
  }


  ////////////////
  // TESTIMONIES SECTION 
  ///////////////

  .testimonies {

    padding-bottom: 70px;
    

  	.title {
  		text-align: center;
  		padding-bottom: 30px;
  		color: $title-color;
  	}

    .flex:not(:last-child) {
      margin-bottom: 160px;

      @media (max-width: 750px) {
        margin-bottom: 50px;
      }
    }

  	.flex {
  		display: flex;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;

      @media (max-width: 750px) {
        flex-direction: column;
      }
   


  		.img {
  			width: 25%;
  			margin-right: 70px;
  			

  			img {
  				width: 100%;
        }
        
        @media(max-width: 1200px) {
          width: 30%;
        }

        @media(max-width: 1000px) {
          width: 40%;
        }

        @media(max-width: 850px) {
          width: 45%;
        }

        @media(max-width: 768px) {
          width: 65%;
        }

        @media (max-width: 600px) {
          width: 75%;
        }

        @media (max-width: 420px) {
          width: 80%;
        }

        @media (max-width: 375px) {
          width: 85%;
        }

        @media (max-width: 325px) {
          width: 90%;
        }
 

  		}

  		.content {
        width: 35%;

  			h2 {
  				font-size: 50px;
  				margin: 0;
  				color: $title-color;
  			}

  			.line {
  				@include line(100px);
  				height: 6px;
  				margin-top: 20px;
          margin-bottom: 20px;
          
          @media (max-width: 420px) {
            width: 90px;
          }

          @media (max-width: 375px) {
            width: 80px;
          }
  			}

  			p {
  				@include p(75%);
          margin-bottom: 15px;
  			}

        a {
          font-weight: 700;
          text-decoration: underline;
          font-size: 17px;
          color: $accent-color;
        }

        @media (max-width: 1580px) {
          width: 50%;
        }

        @media (max-width: 1080px) {
          width: 70%;
          h2 {
            font-size: 45px;
          }
        }

        @media (max-width: 850px) {
          h2 {
            font-size: 40px;
          }

          p {
            max-width: 90%;
          }
        }

        @media (max-width: 768px) {
          p {
            max-width: 100%;
          }
        }

        @media (max-width: 750px) {
          width: 100%;
          margin-top: 20px;

          h2 {
            font-size: 50px;
          }
        } 

        @media (max-width: 600px) {
          h2 {
            font-size: 40px;
          }
        }

        @media (max-width: 600px) {
          h2 {
            font-size: 35px;
          }
        }

        @media (max-width: 450px) {
          h2 {
            font-size: 32px;
          }
        }

        @media (max-width: 420px) {
          h2 {
            font-size: 30px;
          }
        }

        @media (max-width: 375px) {
          h2 {
            font-size: 27px;
          }
        }
  		}

  	}

    .reverse {
      .img {
        order: 2;

        @media (max-width: 1200px) {
          margin-top: 15px;
        }

        @media (max-width: 768px) {
          margin-right: 20px;
        }
        
        @media (max-width: 750px) {
          order: -1;
        }
      }

      .content {
        margin-right: 70px;

        @media (max-width: 1080px) {
          margin-right: 40px;
        }


        
      }

    }

  }


  ////////////////
  // YOUTUBE SECTION 
  ///////////////


  .youtube {
    
    padding-top: 80px;
    padding-bottom: 120px;
    text-align: center;

    @media (max-width: 830px) {
      padding-bottom: 80px;
    }

    @media (max-width: 430px) {
      padding-bottom: 60px;
      padding-top: 40px;
    }

    .title {

      margin-bottom: 40px;

      h1 {
        font-size: 40px;
        margin: 0;
        color: $title-color;

        @media (max-width: 430px) {
          font-size: 35px;
        }

      }

      .line {
        @include line(85px);
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 15px;
      
      }
    }

    .flex {

      display: flex;

      @media (max-width: 830px) {
        flex-direction: column;

      }

      .icon {
        width: 30%;
        height: 100%;
        flex: 1;

        img {
          width: 40%;
          height: auto;
        }

        a {
             
              text-transform: uppercase;
              color: $title-color;
              font-weight: 700;
              margin: 0;
              margin-top: 5px;
              margin-left: auto;
              margin-right: auto;
              font-size: 25px;
              font-family: 'Open Sans'

            
          }

          @media (max-width: 830px) {
            width: 100%;
            display: block;

            img {
              width: 15%;
            }

  
          }

          @media (max-width: 650px) {
            img {
              width: 20%;
            }

            a {
              font-size: 22px;
            }
          }

          @media (max-width: 430px) {
            img {
              width: 30%;
            }

            a {
              font-size: 21px;
            }
          }
      }

      .content {

        flex: 2;
        margin-top: 50px;

        p {
          @include p(100%);
          font-size: 20px;
          line-height: 32px;
        }

        @media (max-width: 930px) {
          p {
            font-size: 18px;
          }
        }

        @media (max-width: 550px) {
          margin-top: 30px;
        }

        @media (max-width: 375px) {
          margin-top: 20px;

          p {
            font-size: 16px;
          }
        }
      }
    }


  }


  ////////////////
  // FOOTER SECTION 
  ///////////////
  

  .footer {

    background-color: $footer-color;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: left;


    .logo {
      width: 20%;
      margin-bottom: 60px;
    }

    .flex {
      display: flex;



      .footer-nav {
        margin-right: auto;
        margin-top: 4px;

        li {
          margin-right: 30px;

          @media (max-width: 1220px) {
            margin-right: 30px;
          }
  
  
          @media (max-width: 1080px) {
            margin-right: 20px;
          }

        }

       

      }
      
      ul {

        li {
          display: inline-block;
          
          
        
          


          a {
            color: #fff;

            &:hover {
              color: rgba(255, 255, 255, 0.6);
            }

          }
        }


      }

    }



    .social {

      li {
        margin-right: 15px;
      }

  

      img {
        width: 22px;
        height: 22px;
        position: relative;
        top: 7px;
        left: 5px;
        margin-right: 12px;

      }  

    

        a {
          font-size: 12px;
          letter-spacing: 1px;
          color: #fff;
          transition: 0.3s;

          &:hover {
            color: rgba(255, 255, 255, 0.6);
          }
        }

    }


    @media (max-width: 1140px) {

      text-align: center;
      padding-top: 70px;
      padding-bottom: 70px;

      .logo {
        width: 35%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
      }
      
      .flex {
        flex-direction: column;


        .footer-nav {
          display: flex;
          width: 90%;

          justify-content: space-between;
          margin: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          padding-bottom: 30px;
          margin-left: auto;
          margin-right: auto;

          li {
            margin: 0;
          }

        }

        .social {
          margin-top: 60px;
          display: flex;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-around;
          margin-left: 60px;

        } 

      }
         
    }

    @media (max-width: 667px) {
      
      .flex {
        .social {
          margin-left: 35px;
        }
      }
    }

    @media (max-width:681px) {
        
        text-align: left;

        .logo {
          margin-left: 20px;
          margin-right: 0;
        }

      .flex {


        .footer-nav {
          flex-wrap: wrap;
          flex-direction: column;
        }

        .social {
          margin-top: 30px;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: start;
          margin-left: 20px;

          li {
            margin-top: 5px;
            margin-bottom: 5px;

            img {
              margin-right: 15px;
            }
          }
        }

      }
    }

    @media (max-width: 471px) {

      .logo {
        width: 45%;
        margin-bottom: 30px;
        margin-left: 10px;
      }

      .flex {
        .social {
          margin-left: 10px;
        }
      }
    }




  }







  /////////////////////////////////////////////
  // ABOUT ME PAGE
  ////////////////////////////////////////////


  #page {

    .navbar {
      border-bottom: 1px solid #ccc;
    }

    .about {
      
      padding-top: 100px;
      padding-bottom: 70px;

      @media (max-width: 700px) {
        padding-top: 80px;
        padding-bottom: 50px;
      }

      @media (max-width: 575px) {
        padding-bottom: 30px;
      }

      @media (max-width: 500px) {
        padding-top: 60px;
       
      }

      
      @media (max-width: 375px) {
        padding-top: 50px;
       
      }

      .title {
        text-align: center;
        padding-bottom: 30px;
        color: $title-color;
      }

      .flex:not(:last-child) {
        margin-bottom: 160px;

        @media (max-width: 750px) {
          margin-bottom: 50px;
        }
      }

      .flex {
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        justify-content: center;

        @media (max-width: 750px) {
          flex-direction: column;
        }
     


        .img {
          width: 28%;
          margin-right: 70px;
          

          img {
            width: 100%;
          }

          @media(max-width: 1200px) {
            width: 30%;
          }
  
          @media(max-width: 1000px) {
            width: 40%;
          }
  
          @media(max-width: 850px) {
            width: 45%;
          }
  
          @media(max-width: 768px) {
            width: 65%;
          }
  
          @media (max-width: 600px) {
            width: 75%;
          }
  
          @media (max-width: 420px) {
            width: 80%;
          }
  
          @media (max-width: 375px) {
            width: 85%;
          }
  
          @media (max-width: 325px) {
            width: 90%;
          }

        }

        .content {
          width: 35%;

          h2 {
            font-size: 50px;
            margin: 0;
            color: $title-color;
          }

          .line {
            @include line(100px);
            height: 6px;
            margin-top: 20px;
            margin-bottom: 20px;

            @media (max-width: 420px) {
              width: 90px;
            }
  
            @media (max-width: 375px) {
              width: 80px;
            }

          }

          p {
            @include p(75%);
            margin-bottom: 15px;
          }

          a {
            font-weight: 700;
            text-decoration: underline;
            font-size: 17px;
            color: $accent-color;
          }

          @media (max-width: 1580px) {
            width: 50%;
          }
  
          @media (max-width: 1080px) {
            width: 70%;
            h2 {
              font-size: 45px;
            }
          }
  
          @media (max-width: 850px) {
            h2 {
              font-size: 40px;
            }
  
            p {
              max-width: 90%;
            }
          }
  
          @media (max-width: 768px) {
            p {
              max-width: 100%;
            }
          }
  
          @media (max-width: 750px) {
            width: 100%;
            margin-top: 20px;
  
            h2 {
              font-size: 50px;
            }
          } 
  
          @media (max-width: 600px) {
            h2 {
              font-size: 40px;
            }
          }
  
          @media (max-width: 600px) {
            h2 {
              font-size: 35px;
            }
          }
  
          @media (max-width: 450px) {
            h2 {
              font-size: 32px;
            }
          }
  
          @media (max-width: 420px) {
            h2 {
              font-size: 30px;
            }
          }
  
          @media (max-width: 375px) {
            h2 {
              font-size: 27px;
            }
          }
        }

      }

      .reverse {
        .img {
          order: 2;
        }

        .content {
          margin-right: 70px;
        }

      }

    }


    .ministries {

      .title {

        padding-bottom: 30px;

        h2 {
          font-size: 50px;
          margin: 0;
          color: $title-color;

          @media (max-width: 575px) {
            font-size: 45px;
          }

          @media (max-width: 420px) {
            font-size: 40px;
          }
        }

        .line {
          @include line(110px);
          height: 10px;
          margin-top: 10px;
          margin-bottom: 20px;

          @media (max-width: 575px) {
            margin-bottom: 5px
          }
        }
      }

      .col {

        padding-bottom: 30px;

        h2 {
          color: $title-color;
          margin: 0;
        }

        p {
          @include p(75%);
          
          margin-top: 10px;

          @media (max-width: 575px) {
            max-width: 95%;
          }

        }

      }
    }

    /////////////////////////////////////////////
    //  CONTACT PAGE
    ////////////////////////////////////////////

    .contact {
      padding-top: 100px;
      padding-bottom: 70px;
      text-align: center;

      @media (max-width: 770px) {
        padding-top: 80px;
      }

      @media (max-width: 560px) {
        padding-top: 50px;
      }

      .title {

        padding-bottom: 30px;

        @media (max-width: 560px) {
          padding-bottom: 0px;
        }

        h2 {
          font-size: 50px;
          margin: 0;
          color: $title-color;

          @media (max-width: 770px) {
            font-size: 45px;
          }

          @media (max-width: 560px) {
            font-size: 40px;
          }

          @media (max-width: 415px) {
            font-size: 35px;
          }

          @media (max-width: 375px) {
            font-size: 32px;
          }
        }

        .line {
          @include line(110px);
          height: 10px;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 20px;

          @media (max-width: 560px) {
            width: 100px;
          }

          @media (max-width: 415px) {
            width: 100px;
            height: 8px;
          }

        }
      }

      .contact-form {
        
      
        text-align: left;
      

        .form-container {

          display: flex;
          flex-direction: column;
          align-content: center;
          width: 100%;
          max-width: 50%;
          margin: 0 auto;

          @media (max-width: 1000px) {
            max-width: 70%;
          }

          @media (max-width: 560px) {
            max-width: 85%;
          }

          @media (max-width: 420px) {
            max-width: 100%;
          }
         

          label {
            font-size: 20px;
            //padding-bottom: 10px;
            padding-top: 40px;
            color: #66666d;
            font-weight: 400;

            
          }

          input,
          textarea {
            border: 1px solid #dfe7ec;
            border-radius: 3px;
            width: 100%;
            margin: 0 auto;
            
          }

          textarea {
            resize: none;
            height: 130px;
            margin-bottom: 20px;
          }

          input::placeholder,
          textarea::placeholder {
            color: #b2b2b2;
          }

          .btn {
            position: relative;
            width: 30%;
            padding: 13px 20px;
            border-radius: 25px;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #fff;
          //  border: 2px solid $accent-color;
            background-color: $accent-color;
            font-weight: 600;
            transition: .3s;
            cursor: pointer;

            @media (max-width: 835px) {
              width: 40%;
            }

            @media (max-width: 415px) {
              width: 60%;
            }

            @media (max-width: 350px) {
              width: 65%;
            }
            
            
            &:hover {
              top: -1px;
              background-color: rgba($accent-color, 0.88);
              box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

            }  
          }

        }

     

      }
    }


    /////////////////////////////////////////////
    //  TESTIMONIES PAGE
    ////////////////////////////////////////////


    /////////////
    // Main Header Section
    /////////////
    
    .main-header {
      background-image: url('../images/tiha-voda.jpg');
      height: 540px;
      background-position: center 20%;
      background-size: cover;

     .container {
        display: flex;
        justify-content: center;
        height: 540px;

        .title {
          width: 100%;
          h1 {
            color: #fff;
            font-size: 55px;

            @media (max-width: 740px) {
              font-size: 50px;
            }

            @media (max-width: 620px) {
              font-size: 45px;
            }

            @media (max-width: 500px) {
              font-size: 40px;
            }
          
          }
        }
     }

    }

    /////////////
    // Articles Section
    /////////////

    .articles {
      padding-top: 60px;
      padding-bottom: 100px;

      .title {
        padding-bottom: 50px;
        

        h2 {
          font-size: 50px;
          margin: 0;
          color: $title-color;

          @media (max-width: 770px) {
            font-size: 45px;
          }

          @media (max-width: 560px) {
            font-size: 36px;
          }

          @media (max-width: 415px) {
            font-size: 35px;
          }

          @media (max-width: 400px) {
            font-size: 30px;
          }

        }

        .line {
          @include line(110px);
          height: 10px;
          margin-top: 10px;
          margin-bottom: 20px;
          
          @media (max-width: 560px) {
            width: 100px;
            margin-bottom: 0;
          }

          @media (max-width: 415px) {
            width: 100px;
            height: 8px;
           
          }
         

        }
      }
      

      .grid-container {
       display: grid;
       grid-gap: 20px;
       
       grid-auto-rows: 1fr;
       grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
       margin: 0 auto;
        

      }


      .box {
        width: 100%;
        background-color: #f1f3f5;
        border-radius: 5px;
        transition: all .2s ease-in-out;

        &:hover {
          box-shadow: 0 10px 15px -10px rgba(30,45,62,.21), 0 5px 40px -10px rgba(31,44,60,.1);
            
            .img {
              .overlay {
                opacity: 0.3;
              }
            }
        }

        a {
          color:#394956;
          display: block;
          transition: all .2s ease-in-out;
          width: 100%;
          height: 100%;
        } 

        .img {

          position: relative;
      
          img {
            width: 100%;
            height: auto;
            border-radius: 4px 4px 0 0;

          
          }

          .overlay {
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            width: 100%;
            height: 97%;
            z-index: 9999;
            top: 0; right: 0; bottom: 0; left: 0;
            opacity: 0;
            transition: all .2s ease-in-out;

            @media (max-width: 680px) {
              height: 98%;
            }

            
          }

        }

        .content {
         text-align: center;
         padding: 30px;
         padding-bottom: 5px;
                   
            

          span {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            color: #93a1af;
          }

           p {
            font-size: 18px;
            margin: 0;
            margin-top: 15px;
            margin-bottom: 15px;
           }

        }
      }
    }




    /////////////
    // Article Text Section
    /////////////

    .post {
      padding-top: 50px;
      padding-bottom: 70px;

    }

    .blog-container {

     
        width: 100%;
        max-width: 690px;
        margin: 0 auto;
        padding: 0 20px;

        h2 {
            font-size: 40px;
            margin: 0;
            margin-bottom: 30px;
            color: $title-color;
            text-align: center;
          }

       /*    .line {
         @include line(110px)
         height: 10px;
         margin: 0 auto;
         margin-bottom: 20px;
         margin-top: 10px;
       
       } */

       p {
          @include p(100%); 
          margin-top: 10px;

       }

       h3 {
        font-size: 25px;
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $title-color;

       }



        .img {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 30px;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }


    }

    	//////////////
	// GALLERY PAGE
	//////////////


	.gallery {
		text-align: center;
    max-width: 1320px;
    padding-bottom: 80px;

		.title {

      margin-bottom: 50px;
      
      h1 {
        font-size: 45px;
        color: $title-color;
      //  text-align: left;
        margin: 0;
        margin-top: 30px;
        margin-bottom: 10px;
      }

			.line {
        margin: 0 auto;
        height: 10px;
        width: 100px;
        background-color: $accent-color;
			}
		}

		.text {
			margin-bottom: 35px;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 1px;
			h2 {
				font-weight: 500;
			}
		}

		.wrapper {
			display: grid;
			grid-gap: 30px;
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			grid-auto-rows: 150px;
			grid-auto-flow: row dense;

			.item {
			  position: relative;
			  grid-column-start: auto;
			  grid-row-start: auto;
			  background-image: url('../images/gallery/Trudnoca\ II\ i\ Jovan\ 1\ 092.jpg');
			  background-size: cover;
			  background-position: center;
			  box-shadow: -2px 2px 10px 0px rgba(#444, 0.4);
			  transition: transform 0.3s ease-in-out;
			  cursor: pointer;

			  a {
			  	color: #fff;
			  	font-size: 50px;
			  	position: absolute;
			  	width: 100%;
			  	height: 100%;
			  	top: 0;
			  	left: 0;
			  	z-index: 9999;
			  }
			  
			  &:nth-child(2) {
			    background-image: url('../images/gallery/2.jpg');
			  }
			  
			  &:nth-child(3) {
			    background-image: url('../images/gallery/3.jpg');
			  }
			  
			  &:nth-child(4) {
			    background-image: url('../images/gallery/4.jpg');
			  }
			  
			  &:nth-child(5) {
			    background-image: url('../images/gallery/DSP_2053-75.jpg');

				}

			   &:nth-child(6) {
			    background-image: url('../images/gallery/6.jpg');
			  }
			  

			   &:nth-child(7) {
			    background-image: url('../images/gallery/7.jpg');
			  }
			  

			   &:nth-child(8) {
			    background-image: url('../images/gallery/DSP_2100-120.jpg');
			  }
			  

			   &:nth-child(9) {
			    background-image: url('../images/gallery/9.jpg');
			  }
			  

			   &:nth-child(10) {
			    background-image: url('../images/gallery/10.jpg');
			  }
			  

			   &:nth-child(11) {
			    background-image: url('../images/gallery/11.jpg');
			  }
			  

			   &:nth-child(12) {
			    background-image: url('../images/gallery/12.jpg');
			  }
			  

			   &:nth-child(13) {
			    background-image: url('../images/gallery/13.jpg');
			  }
			  

			   &:nth-child(14) {
			    background-image: url('../images/gallery/14.jpg');
			  }
			  


			   &:nth-child(15) {
			    background-image: url('../images/gallery/15.jpg');
			  }
			  

			   &:nth-child(16) {
			    background-image: url('../images/gallery/16.jpg');
			  }

			  &:nth-child(17) {
			    background-image: url('../images/gallery/17.jpg');
			  }

			  &:nth-child(18) {
			    background-image: url('../images/gallery/18.jpg');
			  }

			  &:nth-child(19) {
			    background-image: url('../images/gallery/ABP_2135.JPG');
			  }

			  &:nth-child(20) {
			    background-image: url('../images/gallery/DSC_8735.jpg');
			  }

			  &:nth-child(21) {
			    background-image: url('../images/gallery/DSP_1978-5.jpg');
			  }

			  &:nth-child(22) {
			    background-image: url('../images/gallery/DSP_1993-19.jpg');
        }
        
        &:nth-child(23) {
			    background-image: url('../images/gallery/DSP_2000-26.jpg');
        }
        
        &:nth-child(24) {
			    background-image: url('../images/gallery/5.jpg');
        }
        
        &:nth-child(25) {
			    background-image: url('../images/gallery/8.jpg');
        }
        
        &:nth-child(26) {
			    background-image: url('../images/gallery/IMG_6853.JPG');
        }
        
        &:nth-child(27) {
			    background-image: url('../images/gallery/IMG_6866.JPG');
        }
        
        &:nth-child(28) {
			    background-image: url('../images/gallery/IMG_6895.JPG');
        }
        
        &:nth-child(29) {
			    background-image: url('../images/gallery/Juzna\ Koreja\ i\ nova\ 2010.\ 159.jpg');
        }
        
        &:nth-child(30) {
			    background-image: url('../images/gallery/Juzna\ Koreja\ i\ nova\ 2010.\ 171.jpg');
        }
        
        &:nth-child(31) {
			    background-image: url('../images/gallery/Juzna\ Koreja\ i\ nova\ 2010.\ 463.jpg');
        }
        
        &:nth-child(32) {
			    background-image: url('../images/gallery/Juzna\ Koreja\ i\ nova\ 2010.\ 639.jpg');
        }
        
        &:nth-child(33) {
			    background-image: url('../images/gallery/Juzna\ Koreja\ i\ nova\ 2010.\ 640.jpg');
        }
        
        &:nth-child(34) {
			    background-image: url('../images/gallery/Mađarska\ Slovačka\ Nemačka\ 192.jpg');
			  }
        
        &:nth-child(35) {
			    background-image: url('../images/gallery/Od\ januara\ 2014\ Kopaonik\ 012.jpg');
			  }
        
        &:nth-child(36) {
			    background-image: url('../images/gallery/Od\ januara\ 2014\ Kopaonik\ 013.jpg');
        }

        &:nth-child(37) {
			    background-image: url('../images/gallery/Od\ januara\ 2014\ Kopaonik\ 025.jpg');
        }
        
        &:nth-child(38) {
			    background-image: url('../images/gallery/Od\ januara\ 2014\ Kopaonik\ 033.jpg');
        }
        
        &:nth-child(39) {
			    background-image: url('../images/gallery/Od\ januara\ 2014\ Kopaonik\ 042.jpg');
        }
        
        &:nth-child(40) {
			    background-image: url('../images/gallery/Porodica\ i\ Juzna\ Koreja\ 486.jpg');
        }
        
        &:nth-child(41) {
			    background-image: url('../images/gallery/Rukopolaganje\ i\ prvi\ rodus\ 024.jpg');
        }
        
        &:nth-child(42) {
			    background-image: url('../images/gallery/SDC10657.JPG');
        }
        
        &:nth-child(43) {
			    background-image: url('../images/gallery/Sve\ u\ 2016\ 328.jpg');
        }
        
        &:nth-child(44) {
			    background-image: url('../images/gallery/Sve\ u\ 2016\ 329.jpg');
        }
        
        &:nth-child(45) {
			    background-image: url('../images/gallery/Trudnoca\ II\ i\ Jovan\ 1\ 092.jpg');
			  }
        
			  &:after {
			    content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    background-color: black;
			    opacity: 0.3;
			    transition: opacity 0.3s ease-in-out;
			  }
			  
			  &:hover {
			    transform: scale(1.05);
			    
			    &:after {
			      opacity: 0;
			    }
			  }

			  &--medium {
			    grid-row-end: span 2;
			  }

			  &--large {
			    grid-row-end: span 3;
			  }

			  &--full {
			  	 grid-column: 1/-1;
			     grid-row-end: span 3;
			    

			    @media screen and (max-width: 768px) {
			     
			      grid-column-end: auto;
			    }
			  }

			  .item__details {
			    position: absolute;
			    width: 100%;
			    bottom: 0;
			    z-index: 1;
			    padding: 15px;
			    color: #444;
			    background: #fff;
			    
			    letter-spacing: 1px;
			    color: #828282;
			  }

			
			  

			}
		}


		
  		
    }
    

    .news {

      padding-top: 30px;
      padding-bottom: 50px;

      .title {
        
        padding-bottom: 20px;

        h2 {
          font-size: 50px;
  				margin: 0;
          color: $title-color;
          
          @media (max-width: 770px) {
            font-size: 45px;
          }

          @media (max-width: 560px) {
            font-size: 40px;
          }

          @media (max-width: 415px) {
            font-size: 35px;
          }

          @media (max-width: 375px) {
            font-size: 32px;
          }
        }

        .line {
          @include line(110px);
          
          height: 8px;
          margin-top: 8px;
          margin-left: 2px;
          margin-bottom: 20px;

          @media (max-width: 560px) {
            width: 90px;
          }

          @media (max-width: 415px) {
            width: 90px;
            
            height: 8px;
          }
        }
      }

      .content {

        display: flex;
        flex-wrap: wrap;

        .box {
          width: 45%;
          position: relative;
          height: auto;
          border-radius: 5px;
          margin: 20px;
          padding-bottom: 50px;
          box-shadow: 0px 2px 4px rgba(0,0,0,0.18); 

          @media (max-width: 858px) {
            width: 100%;
            margin-bottom: 35px;
          }

          @media (max-width: 400px) {
            width: 100%;
            margin-bottom: 25px;
          }

          h3 {
            color: $title-color;
            font-size: 20px;
            margin: 0;
            margin-left: 35px;
            margin-right: 35px;
            margin-top: 70px;

            @media (max-width: 400px) {
             
              margin-left: 25px;
            }

            
          }

          p {
            margin: 0;
            margin-top: 12px;
            margin-left: 35px;
            
            max-width: 75%;
            width: 100%;
            color: $p-color;

            @media (max-width: 858px) {
             max-width: 80%;
            }

            @media (max-width: 400px) {
              max-width: 84%;
              margin-left: 25px;
            }
          }

          .date {
            position: absolute;
            top: 0;
            border-radius: 3px;
            font-weight: 400;
            letter-spacing: 1px;
            color: #fff;
            background-color: $accent-color;
            padding: 7px 17px;
            
            
          }
          
        }
      }

     
    }
  
  }







