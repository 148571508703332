
// Color

$main-color: #233142;
$secondary-color: #95E1D3;
$accent-color: #f73859;
$button-color: #e34e09;
$title-color: #233142;
$p-color: #586368;
$footer-color: #384259;
$background-color: #DBE2EF;

// Media Queries

$xxs: 375px;
$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1150px;

//393749


//E27A3B

// /473BE2


// FLAG VARIABLES

$flag-icon-css-path: '../flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;
