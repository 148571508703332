


@mixin responsive($width) {
  @if $width == lg {
    @media (max-width: $lg) { @content; }
  }
  @else if $width == md {
    @media (max-width: $md) { @content; }
  }
  @else if $width == sm {
    @media (max-width: $sm)  { @content; }
  }
  @else if $width == xs {
    @media (max-width: $xs)  { @content; }
  }
   @else if $width == xxs {
    @media (max-width: $xxs)  { @content; }
  }
}

@mixin button {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.5px;
  padding: 10px 35px;
  border: 2px solid white;
  transition: .3s ease-in-out;
  border-radius: 50px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@mixin title {
      color: $title-color;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
}

@mixin line($width) {
  width: $width;
  height: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: $accent-color;
}

@mixin p($max-width) {
  max-width: $max-width;
  width: 100%;
  margin: 0;
  margin-bottom: 5px;
  line-height: 26px;
  color: $p-color;
}